@tailwind base;
@tailwind components;
@tailwind utilities;
/*
    Safari and Firefox use SF for -apple-system; Chrome recognizes BlinkMacSystemFont
    See: https://stackoverflow.com/questions/32660748/how-to-use-apples-san-francisco-font-on-a-webpage
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 300;
}
